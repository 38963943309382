:root {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-e95a17e: #e82020;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--base: #ffffff;
  --wp--preset--color--contrast: #000000;
  --wp--preset--color--primary: #9dff20;
  --wp--preset--color--secondary: #345c00;
  --wp--preset--color--tertiary: #f6f6f6;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
  --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
  --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
  --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
  --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
  --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
  --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
  --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
  --wp--preset--font-size--small: clamp(
    0.875rem,
    0.875rem + ((1vw - 0.48rem) * 0.24),
    1rem
  );
  --wp--preset--font-size--medium: clamp(
    1rem,
    1rem + ((1vw - 0.48rem) * 0.24),
    1.125rem
  );
  --wp--preset--font-size--large: clamp(
    1.75rem,
    1.75rem + ((1vw - 0.48rem) * 0.24),
    1.875rem
  );
  --wp--preset--font-size--x-large: 2.25rem;
  --wp--preset--font-size--xx-large: clamp(
    4rem,
    4rem + ((1vw - 0.48rem) * 11.538),
    10rem
  );
  --wp--preset--font-family--dm-sans: "DM Sans", sans-serif;
  --wp--preset--font-family--ibm-plex-mono: "IBM Plex Mono", monospace;
  --wp--preset--font-family--inter: "Inter", sans-serif;
  --wp--preset--font-family--system-font: -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  --wp--preset--font-family--source-serif-pro: "Source Serif Pro", serif;
  --wp--preset--spacing--30: clamp(1.5rem, 5vw, 2rem);
  --wp--preset--spacing--40: clamp(
    1.8rem,
    1.8rem + ((1vw - 0.48rem) * 2.885),
    3rem
  );
  --wp--preset--spacing--50: clamp(2.5rem, 8vw, 4.5rem);
  --wp--preset--spacing--60: clamp(3.75rem, 10vw, 7rem);
  --wp--preset--spacing--70: clamp(
    5rem,
    5.25rem + ((1vw - 0.48rem) * 9.096),
    8rem
  );
  --wp--preset--spacing--80: clamp(7rem, 14vw, 11rem);
}

@font-face {
  font-family: "bebas";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("./fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: eicons;
  src: url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.eot?5.18.0);
  src: url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.eot?5.18.0#iefix)
      format("embedded-opentype"),
    url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.woff2?5.18.0)
      format("woff2"),
    url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.woff?5.18.0)
      format("woff"),
    url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.ttf?5.18.0)
      format("truetype"),
    url(http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/eicons/fonts/eicons.svg?5.18.0#eicon)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

body {
  max-width: 767px;
  margin: auto;
}

@media all {
  [class^="eicon"] {
    display: inline-block;
    font-family: eicons;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .eicon-chevron-right:before {
    content: "\e87d";
  }
  .eicon-chevron-left:before {
    content: "\e87e";
  }
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/css/common.min.css?ver=3.11.5 ; media=all */
@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
/*! CSS Used from: Embedded */
html :where(img[class*="wp-image-"]) {
  height: auto;
  max-width: 100%;
}
figure {
  margin: 0 0 1em;
}
/*! CSS Used from: Embedded */
h3,
h4,
h5 {
  font-weight: 400;
  line-height: 1.4;
}
h3 {
  font-size: var(--wp--preset--font-size--x-large);
}
h4 {
  font-size: var(--wp--preset--font-size--large);
}
h5 {
  font-size: var(--wp--preset--font-size--medium);
  font-weight: 700;
  text-transform: uppercase;
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.11.5 ; media=all */
@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .elementor *,
  .elementor :after,
  .elementor :before {
    box-sizing: border-box;
  }
  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .elementor
    .elementor-widget:not(.elementor-widget-text-editor):not(
      .elementor-widget-theme-post-content
    )
    figure {
    margin: 0;
  }
  .elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
  }
  .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
  }
  .elementor-section {
    position: relative;
  }
  .elementor-section .elementor-container {
    /* display: flex; */
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
  @media (max-width: 1024px) {
    .elementor-section .elementor-container {
      /* flex-wrap: wrap; */
    }
  }
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }
  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
  }
  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }
  .elementor-widget-wrap.e-swiper-container {
    width: calc(
      100% -
        (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px))
    );
  }
  .elementor-widget {
    position: relative;
  }
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
  .elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
  }
  .elementor-column-gap-default-categories {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .elementor-column-gap-default
    > .elementor-column
    > .elementor-element-populated {
    padding: 10px;
  }
  @media (min-width: 768px) {
    .elementor-column.elementor-col-33 {
      /* width: 33.333%; */
    }
    .elementor-column.elementor-col-66 {
      /* width: 66.666%; */
    }
    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .elementor-column {
      /* width: 100%; */
    }
  }
  .elementor-element .elementor-widget-container {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
  }
  .elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }
  .elementor-element .swiper .swiper-slide figure {
    line-height: 0;
  }
  .elementor-element .swiper .elementor-swiper-button {
    position: absolute;
    display: inline-flex;
    z-index: 1;
    cursor: pointer;
    font-size: 25px;
    color: hsla(0, 0%, 93.3%, 0.9);
    top: 50%;
    transform: translateY(-50%);
  }
  .elementor-element .swiper .elementor-swiper-button-prev {
    left: 10px;
  }
  .elementor-element .swiper .elementor-swiper-button-next {
    right: 10px;
  }
  .elementor-element
    .swiper
    .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 5px;
  }
  .elementor-element.elementor-pagination-position-outside .swiper {
    padding-bottom: 30px;
  }
  .elementor-element.elementor-pagination-position-outside
    .swiper
    .elementor-swiper-button {
    top: calc(50% - 30px / 2);
  }
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css?ver=8.4.5 ; media=all */
@media all {
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-pointer-events {
    touch-action: pan-y;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 8px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 8px)
    );
    display: inline-block;
    border-radius: 50%;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  }
  .swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/uploads/elementor/css/post-6.css?ver=1679569261 ; media=all */
@media all {
  .elementor-kit-6 h1 {
    color: var(--e-global-color-e95a17e);
  }
  .elementor-kit-6 h3 {
    color: var(--e-global-color-e95a17e);
  }
  .elementor-kit-6 h4 {
    color: var(--e-global-color-e95a17e);
  }
  .elementor-kit-6 h5 {
    color: var(--e-global-color-e95a17e);
  }
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
  .elementor-element {
    --widgets-spacing: 20px;
  }
  @media (max-width: 1024px) {
    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 1024px;
    }
  }
  @media (max-width: 767px) {
    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 767px;
    }
  }
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/uploads/elementor/css/global.css?ver=1679569262 ; media=all */
@media all {
  .elementor-widget-heading .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-primary-font-family), Sans-serif;
    font-weight: var(--e-global-typography-primary-font-weight);
  }
  .elementor-widget-text-editor {
    color: var(--e-global-color-text);
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
    font-weight: var(--e-global-typography-text-font-weight);
  }
}
/*! CSS Used from: http://intable.dsoft-lb.net/wp-content/uploads/elementor/css/post-9.css?ver=1679570500 ; media=all */
@media all {
  .elementor-9 .elementor-element.elementor-element-5052716 img {
    width: 13%;
  }
  .elementor-9 .elementor-element.elementor-element-f1ccce2 {
    --e-image-carousel-slides-to-show: 1;
  }
  .elementor-9
    .elementor-element.elementor-element-f1ccce2
    .elementor-swiper-button.elementor-swiper-button-prev,
  .elementor-9
    .elementor-element.elementor-element-f1ccce2
    .elementor-swiper-button.elementor-swiper-button-next {
    font-size: 49px;
  }
  .elementor-9
    .elementor-element.elementor-element-3ec1020
    > .elementor-container {
    max-width: 747px;
  }
  .elementor-9 .elementor-element.elementor-element-0948721 img {
    /* width: 100px;
    height: 100px; */
  }
  .elementor-9 .elementor-element.elementor-element-5bb1d79 {
    text-align: center;
  }
  .elementor-9
    .elementor-element.elementor-element-5bb1d79
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-d915103 img {
    width: 100px;
    height: 100px;
  }
  .elementor-9 .elementor-element.elementor-element-40a8189 {
    text-align: center;
  }
  .elementor-9
    .elementor-element.elementor-element-40a8189
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-509141e img {
    width: 100px;
    height: 100px;
  }
  .elementor-9 .elementor-element.elementor-element-3bbac34 {
    text-align: center;
  }
  .elementor-9
    .elementor-element.elementor-element-3bbac34
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-b6f59b5 {
    --spacer-size: 50px;
  }
  .elementor-9
    .elementor-element.elementor-element-a9702f1
    > .elementor-container {
    max-width: 1133px;
  }
  .elementor-9 .elementor-element.elementor-element-c3bfc25 img {
    width: 100px;
    height: 100px;
  }
  .elementor-9
    .elementor-element.elementor-element-d162f2d.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-d162f2d.elementor-column
    > .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .elementor-9 .elementor-element.elementor-element-274e728 {
    text-align: left;
  }
  .elementor-9
    .elementor-element.elementor-element-274e728
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-c1f85a3
    > .elementor-element-populated {
    padding: 0px 80px 0px 80px;
  }
  .elementor-9
    .elementor-element.elementor-element-bae002a
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-bae002a:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-bae002a {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-bae002a {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-52c935f {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-52c935f img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-fde957d.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-ba893be
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-47c7f66 {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-47c7f66
    .elementor-heading-title {
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-47c7f66
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  .elementor-9
    .elementor-element.elementor-element-643038a
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-643038a:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-643038a {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-643038a {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-3bbe46f {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-3bbe46f img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-0fd5beb.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-a0ceba2
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-5430cd9 {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-5430cd9
    .elementor-heading-title {
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-5430cd9
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  .elementor-9 .elementor-element.elementor-element-3269ca5 {
    --spacer-size: 50px;
  }
  .elementor-9
    .elementor-element.elementor-element-0fc61d6
    > .elementor-container {
    max-width: 1133px;
  }
  .elementor-9 .elementor-element.elementor-element-cea454e img {
    width: 100px;
    height: 100px;
  }
  .elementor-9
    .elementor-element.elementor-element-6d0ed75.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-6d0ed75.elementor-column
    > .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .elementor-9 .elementor-element.elementor-element-76072b1 {
    text-align: left;
  }
  .elementor-9
    .elementor-element.elementor-element-76072b1
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-594b620
    > .elementor-element-populated {
    padding: 0px 80px 0px 80px;
  }
  .elementor-9
    .elementor-element.elementor-element-0c91e72
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-0c91e72:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-0c91e72 {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-0c91e72 {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-db9525b {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-db9525b img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-d7900f7.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-e0b4f78
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-0d436f4 {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-0d436f4
    .elementor-heading-title {
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-0d436f4
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  .elementor-9
    .elementor-element.elementor-element-7f3e97b
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-7f3e97b:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-7f3e97b {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-7f3e97b {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-d65a6ce {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-d65a6ce img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-8e87e5d.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-f12bc8b
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-ad905fe {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-ad905fe
    .elementor-heading-title {
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-ad905fe
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  .elementor-9 .elementor-element.elementor-element-7d1a03a {
    --spacer-size: 50px;
  }
  .elementor-9
    .elementor-element.elementor-element-d3079ff
    > .elementor-container {
    max-width: 1133px;
  }
  .elementor-9 .elementor-element.elementor-element-e6d72ba img {
    width: 100px;
    height: 100px;
  }
  .elementor-9
    .elementor-element.elementor-element-2ce349d.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-2ce349d.elementor-column
    > .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .elementor-9 .elementor-element.elementor-element-9aebab0 {
    text-align: left;
  }
  .elementor-9
    .elementor-element.elementor-element-9aebab0
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-c0fdd74
    > .elementor-element-populated {
    padding: 0px 80px 0px 80px;
  }
  .elementor-9
    .elementor-element.elementor-element-a5dd097
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-a5dd097:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-a5dd097 {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-a5dd097 {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-d8b981e {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-d8b981e img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-6ef36e8.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-271f53d
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-adf3aea {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-adf3aea
    .elementor-heading-title {
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-adf3aea
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  .elementor-9
    .elementor-element.elementor-element-70029b8
    > .elementor-container {
    max-width: 1600px;
  }
  .elementor-9
    .elementor-element.elementor-element-70029b8:not(
      .elementor-motion-effects-element-type-background
    ) {
    background-color: #ffffff;
  }
  .elementor-9 .elementor-element.elementor-element-70029b8 {
    border-radius: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-70029b8 {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-9 .elementor-element.elementor-element-e3ee08d {
    text-align: center;
  }
  .elementor-9 .elementor-element.elementor-element-e3ee08d img {
    width: 100%;
    /* height: 233px; */
    object-fit: cover;
  }
  .elementor-9
    .elementor-element.elementor-element-4bb28c5.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-9
    .elementor-element.elementor-element-cfa683a
    .elementor-heading-title {
    color: var(--e-global-color-e95a17e);
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9 .elementor-element.elementor-element-f6c0326 {
    /* text-align: right; */
  }
  .elementor-9
    .elementor-element.elementor-element-f6c0326
    .elementor-heading-title {
    /* e8b520 */
    color: green;
    font-family: "bebas", Sans-serif;
    font-weight: 600;
  }
  .elementor-9
    .elementor-element.elementor-element-f6c0326
    > .elementor-widget-container {
    padding: 0px 30px 0px 0px;
  }
  @media (min-width: 768px) {
    .elementor-9 .elementor-element.elementor-element-5992c8c {
      /* width: 20%; */
    }
    .elementor-9 .elementor-element.elementor-element-d162f2d {
      /* width: 79.665%; */
    }
    .elementor-9 .elementor-element.elementor-element-c1f85a3 {
      width: 100%;
    }
    .elementor-9 .elementor-element.elementor-element-138ff9a {
      /* width: 20%; */
    }
    .elementor-9 .elementor-element.elementor-element-6d0ed75 {
      /* width: 79.665%; */
    }
    .elementor-9 .elementor-element.elementor-element-594b620 {
      width: 100%;
    }
    .elementor-9 .elementor-element.elementor-element-c3df7f7 {
      /* width: 20%; */
    }
    .elementor-9 .elementor-element.elementor-element-2ce349d {
      /* width: 79.665%; */
    }
    .elementor-9 .elementor-element.elementor-element-c0fdd74 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .elementor-9
      .elementor-element.elementor-element-c1f85a3
      > .elementor-element-populated {
      padding: 0px 10px 0px 10px;
    }

    .elementor-9
      .elementor-element.elementor-element-594b620
      > .elementor-element-populated {
      padding: 0px 10px 0px 10px;
    }

    .elementor-9
      .elementor-element.elementor-element-c0fdd74
      > .elementor-element-populated {
      padding: 0px 10px 0px 10px;
    }
  }
}
/*! CSS Used from: Embedded */
.elementor-widget-image {
  text-align: center;
}
.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}
/*! CSS Used from: Embedded */
.elementor-widget-image-carousel .swiper {
  position: static;
}
.elementor-widget-image-carousel .swiper .swiper-slide figure {
  line-height: inherit;
}
.elementor-widget-image-carousel .swiper-slide {
  text-align: center;
}
.elementor-image-carousel-wrapper:not(.swiper-container-initialized)
  .swiper-slide {
  max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3));
}
/*! CSS Used from: Embedded */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
/*! CSS Used from: Embedded */
.elementor-column .elementor-spacer-inner {
  height: var(--spacer-size);
}
/*! CSS Used fontfaces */
